import { config as endpoints } from '../config/config'
import { AxiosRequestConfig } from 'axios';
import axios from 'axios';

type getRoleListProps = {
  token: string,
  data: DataListRolesProps
}

type DataListRolesProps = {
  appName: string
  useSharedRoles: boolean
  userId?: string
}

type DataAliasesRole = {
  token: string,
  roles: string[]
}

export /**
 * Roles Action getRoles
 *
 * @method GET
 * @param {*}
 */
  const getRoles = async (data: getRoleListProps) => {
    try {
      const config: AxiosRequestConfig = {
        url: '/api/role/listByApp',
        baseURL: endpoints.API_SECURITY_ENDPOINT,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: data === undefined ? '' : data.token,
          ...data.data
        },
        data: data.data
      };
      const { status, data: responseData } = await axios(config);
      return { message: responseData.result, statusCode: status };
    } catch (error) {
      const { information } = error.response.data.result;
      const { statusCode } = error.response.data;
      return { message: information, statusCode };
    }
  };

/**
 * Get a list with alias role 
 * @param data Current aliases role
 * @returns The same list with alias role, if they exist in database
 */
export const getAliasesRoles = async (data: DataAliasesRole) => {
  try {
    const config: AxiosRequestConfig = {
      url: '/api/role/get-aliases-by-role',
      baseURL: endpoints.API_SECURITY_ENDPOINT,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: data?.token,
      },
      data: data.roles
    };
    const { status, data: responseData } = await axios(config);
    return { aliasesRole: responseData.result, statusCode: status };
  } catch (error) {
    const { information } = error.response.data.result;
    const { statusCode } = error.response.data;
    return { message: information, statusCode };
  }
};

/* eslint-disable react/react-in-jsx-scope */
import {
  Divider,
  Drawer,
  DrawerProps,
  Theme,
  Toolbar,
  Typography,
  useTheme
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getMenuItems } from '../../actions/menu.action'
import { LATERAL_MENU } from '../../const/constants'
import { createStyles, makeStyles } from '@material-ui/styles'
import useAsync from '../../hooks/useAsync'
import { DrawMenu } from '../../components/DrawMenu'
import { CustomThemeProvider } from '../../theme/CustomThemeProvider'
import { convertHexToFilter } from '../../utils/HexToFilter'
import { isTokenExpired } from '../../utils/utils'
import { Skeleton } from '@material-ui/lab'
import { DRAWER_WIDTH } from '../../utils/const'

interface jsonColor {
  primaryColor: string;
  secondaryColor: string;
  buttonColor: string;
  textButtonColor: string;
}

type LeftMenuProps = {
  open: boolean
  appName: string
  token: string
  drawerProps?: DrawerProps
  functions: Array<any>
  menuName?: string
  color?: jsonColor
  setValuesMenuLeft?: React.Dispatch<React.SetStateAction<any[]>>
}

/**
 *
 * @param menuList
 * @returns
 */
const LeftMenuComponent = ({ appName, drawerProps, token, functions, menuName, color, setValuesMenuLeft }: LeftMenuProps) => {
  const classes = useStyles()
  const [menuList, setMenuList] = useState([])
  const [versionApp, setVersionApp] = useState("")
  const { result, error, execute, loading } = useAsync({
    asyncFunction: getMenuItems
  })

  const theme = useTheme<Theme>()

  // Tema por defecto que se tiene en el menu.
  const defaultColor = theme.palette.primary.light

  const styleProps = {
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.common.white
    }
  }

  useEffect(() => {
    if (!menuList.length && !loading && token) {
      const bearerToken = token.includes('Bearer') ? token : `Bearer ${token}`
      if (!isTokenExpired(token)) {
        execute({
          token: bearerToken,
          appName,
          idmenulocation: parseInt(LATERAL_MENU, 10)
        })
      }
    }
  }, [token])

  useEffect(() => {
    if (result) {
      if (result.statusCode === 200) {
        if (result.message.records.length) {
          const menuResult = result.message.records[0].menuJson
          const menuEdit = JSON.parse(menuResult).map((item: any) => {
            if(item.name == "[COMPANY_NAME] [DOCUMENT_NUMBER]")
              item.name = menuName
            return item
          })
          setMenuList(menuEdit)
          if (setValuesMenuLeft) setValuesMenuLeft(menuEdit)
          setVersionApp(result.message.records[0].version)
        }
      }
    } else if (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }, [result, error])

  const loaderLeftMenu = () => {
    return (
      <div>
        <Skeleton
          variant='rect'
          width={300}
          height={50}
          style={{ marginTop: 5, marginBottom: 5 }}
        />
        <Divider></Divider>
        <Skeleton
          variant='rect'
          width={300}
          height={50}
          style={{ marginTop: 5, marginBottom: 5 }}
        />
        <Divider></Divider>
        <Skeleton
          variant='rect'
          width={300}
          height={50}
          style={{ marginTop: 5, marginBottom: 5 }}
        />
        <Divider></Divider>
        <Skeleton
          variant='rect'
          width={300}
          height={50}
          style={{ marginTop: 5, marginBottom: 5 }}
        />
        <Divider></Divider>
      </div>
    )
  }

  const functionTest = () => {
    alert("Funciona desde el menu Left")
  }

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
      {...drawerProps}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <div className={classes.itemHeight}>
          {menuList.length ? (
            <DrawMenu
              functions={[{ name: "functionTest", function: functionTest }, ...functions]}
              menu={menuList}
              haveDivider
              haveHoverColor={true}
              props={styleProps}
              isLargePadding={false}
              jsonColor = {color}
              events={{
                onMouseOver: (evt: any) => {
                  const listItem = document.getElementById(evt.target.id)
                  const anchor = listItem?.dataset['url']
                  if (anchor !== undefined) {
                    if (!window.location.href.includes(anchor)) {
                      const icon = document.getElementById(
                        `${evt.target.id}-icon`
                      )
                      const typo = document.getElementById(
                        `${evt.target.id}-typo`
                      )
                      const iconSvg = document.getElementById(
                        `${evt.target.id}-svg`
                      )
                      if (typo) {
                        typo.style.color = defaultColor
                        if (color !== undefined && color.primaryColor.length !== 0) {
                          typo.style.color = color.primaryColor
                        }
                      }
                      if (icon) {
                        icon.style.color = defaultColor
                        if (color !== undefined && color.primaryColor.length !== 0) {
                          icon.style.color = color.primaryColor
                        }
                      }
                      if (iconSvg) {
                        iconSvg.style.filter =
                          convertHexToFilter(defaultColor) || ''
                        if (color !== undefined && color.primaryColor.length !== 0) {
                          iconSvg.style.filter =
                            convertHexToFilter(color.primaryColor) || ''
                        }
                      }
                    }
                  }
                },
                onMouseLeave: (evt: any) => {
                  const icon = document.getElementById(`${evt.target.id}-icon`)
                  const typo = document.getElementById(`${evt.target.id}-typo`)
                  const iconSvg = document.getElementById(`${evt.target.id}-svg`)
                  const listItem = document.getElementById(evt.target.id)
                  const anchor = listItem?.dataset['url']
                  let colorDrawer = ''
                  const setColor = () => {
                    if (anchor !== undefined) {
                      if (window.location.href.includes(anchor)) {
                        colorDrawer = defaultColor
                      } else {
                        colorDrawer = theme.typography.subtitle1.color
                          ? theme.typography.subtitle1.color
                          : 'white'
                      }
                    } else {
                      colorDrawer = theme.typography.subtitle1.color
                        ? theme.typography.subtitle1.color
                        : 'white'
                    }
                  }
                  setColor()
                  if (anchor !== undefined) {
                    if (!window.location.href.includes(anchor)) {
                      if (icon) {
                        icon.style.color = colorDrawer
                      }
                      if (typo) {
                        typo.style.color = colorDrawer
                      }

                      if (iconSvg) {
                        iconSvg.style.filter = convertHexToFilter(colorDrawer) || ''
                      }
                    }
                  }
                },
                onFocus: (evt: any) => {
                  const icon = document.getElementById(`${evt.target.id}-icon`)
                  const typo = document.getElementById(`${evt.target.id}-typo`)
                  const iconSvg = document.getElementById(`${evt.target.id}-svg`)
                  const listItem = document.getElementById(evt.target.id)
                  const anchor = listItem?.dataset['url']
                  if (anchor !== undefined) {
                    if (!window.location.href.includes(anchor)) {
                      if (typo) {
                        typo.style.color = defaultColor
                        if (color !== undefined && color.primaryColor.length !== 0) {
                          typo.style.color = color.primaryColor
                        }
                      }
                      if (icon) {
                        icon.style.color = defaultColor
                        if (color !== undefined && color.primaryColor.length !== 0) {
                          icon.style.color = color.primaryColor
                        }
                      }
                      if (iconSvg) {
                        iconSvg.style.filter =
                          convertHexToFilter(defaultColor) || ''
                        if (color !== undefined && color.primaryColor.length !== 0) {
                          iconSvg.style.filter =
                            convertHexToFilter(color.primaryColor) || ''
                        }
                      }
                    }
                  }
                }
              }}
            />
          ) : (
            loaderLeftMenu()
          )}
        </div>
        <div>
          <Divider
            classes={{
              root: classes.divider
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography style={{ fontSize: "12px", color: "#1D71B8", fontWeight: 200 }}>
              {versionApp}
            </Typography>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        background: theme.palette.action.hover,
        color: theme.palette.common.white
      }
    },
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0
    },
    drawerPaper: {
      width: DRAWER_WIDTH
    },
    drawerContainer: {
      overflow: 'auto',
      marginTop: 10,
      height: "100%"
    },
    itemHeight: {
      height: "97%",
      overflow: "auto"
    },
    divider: {
      backgroundColor: theme.palette.info.main,
      height: '1px'
    },
  })
)

export const LeftMenu = (props: LeftMenuProps) => {
  return (
    <CustomThemeProvider>
      <LeftMenuComponent {...props} />
    </CustomThemeProvider>
  )
}